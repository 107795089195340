import { ButtonResult, ButtonType, PopupSize, dedup, popup } from "ui";
import { PrefixChoices, PrefixChoicesModal } from "./components/PrefixChoicesModal/PrefixChoicesModal";

const translations = window.app.preloadState.translation;

export async function normalizeNames(names: string[], setter?: (newName: string) => void): Promise<string[]> {
    const namesWithInfo = names.map(name => [name, shouldNotBePrefixed(name)] as const);

    const deduplicatedNamesWithoutPrefix = dedup(namesWithInfo.filter(x => !x[1]).map(x => x[0]), x => x);
    const prefixMap = await getPrefixMap(deduplicatedNamesWithoutPrefix);

    if (namesWithInfo.length === 1) {
        setter?.(getName(namesWithInfo[0][0], prefixMap));
    }
    return namesWithInfo.map(x => getName(x[0], prefixMap));
}

const getPrefixMap = async (deduplicatedNamesWithoutPrefix: string[]): Promise<Map<string, string>> => {
    if (deduplicatedNamesWithoutPrefix.length == 0) {
        return new Map<string, string>();
    }
    // prefix search
    const response = await fetch('/api/quick-order/prefixes', {
        method: 'POST',
        body: JSON.stringify(deduplicatedNamesWithoutPrefix),
        headers: {
            "Swecon-Current-Language": window.app.preloadState.currentLanguage,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        console.error(await response.text());
        return new Map<string, string>();
    }
    const prefixChoices: PrefixChoices = await response.json();

    // show modal
    let chosenPrefixes: Record<string, string> = Object.fromEntries(Object.entries(prefixChoices).map(pc => [pc[0], pc[1][0]?.itemNumber ?? pc[0]]));
    const codesInNeedOfPrefix = Object.values(prefixChoices).filter(pc => pc.length > 1).length;
    if (codesInNeedOfPrefix > 0) {
        await popup(
            codesInNeedOfPrefix > 1
                ? translations["quickOrder.prefixDescriptionMultiple"]
                : translations["quickOrder.prefixDescriptionSingle"],
            <PrefixChoicesModal choicesToMake={prefixChoices} chosenPrefixes={chosenPrefixes} />,
            [
                { label: translations["remanufacturedItemPopUp.ok"], result: ButtonResult.Ok, type: ButtonType.Primary }
            ],
            PopupSize.Large
        );
    }

    return new Map<string, string>(Object.entries(chosenPrefixes));
}

const shouldNotBePrefixed = (name: string): boolean => {
    const letterRegExp = /[a-zA-Z]/g;
    const containsLetter = letterRegExp.test(name);
    return containsLetter || name.length < 5;
}

const getName = (name: string, prefixMap: Map<string, string>): string => {
    return prefixMap.get(name) ?? name;
}
