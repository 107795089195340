import { Fragment, useMemo, useState } from "react";
import { InputRadio } from "ui";
import * as styles from "./PrefixChoicesModal.module.scss";

export type PrefixChoices = Record<string, PrefixChoice[]>;

export type PrefixChoice = {
    itemNumber: string;
    displayName: string;
    imageUrl: string;
}

type PrefixChoicesModalProps = {
    choicesToMake: PrefixChoices;
    chosenPrefixes: Record<string, string>;
}

const translations = window.app.preloadState.translation;

export const PrefixChoicesModal = ({ choicesToMake, chosenPrefixes }: PrefixChoicesModalProps) => {

    const choicesArray = useMemo(() => Object.entries(choicesToMake), []);

    const [chosenPrefixIndexes, setChosenPrefixIndexes] = useState(choicesArray.map(_ => 0));

    const selectOption = (choiceIndex: number, optionIndex: number) => {
        const newChosenIndexes = [...chosenPrefixIndexes];
        newChosenIndexes[choiceIndex] = optionIndex;
        newChosenIndexes.forEach((chosenIndex, index) => {
            const choice = choicesArray[index];
            chosenPrefixes[choice[0]] = choice[1][chosenIndex]?.itemNumber ?? choice[0];
        });
        setChosenPrefixIndexes(newChosenIndexes);
    }

    const filteredChoices = choicesArray
        .map((choice, index) => ({ choice, index }))
        .filter(c => c.choice[1].length > 1);

    return <div>
        {filteredChoices
            .map((c, i) => <Fragment key={c.choice[0]}>
                {i > 0 && <hr className={styles.splitter} />}
                <div>
                    <h3 className={styles.optionInout}>{translations["quickOrder.articleNumber"]}: {c.choice[0]}</h3>
                    <div>
                        {c.choice[1].map((option, optionIndex) => <div key={option.itemNumber} className={styles.option}>
                            <InputRadio
                                name={option.itemNumber}
                                label={<div>
                                    <img src={option.imageUrl+"?height=180"} />
                                    <div>
                                        <h4>{option.displayName}</h4>
                                        <div>{option.itemNumber}</div>
                                    </div>
                                </div>}
                                checked={chosenPrefixes[c.choice[0]] === option.itemNumber}
                                onChange={() => selectOption(c.index, optionIndex)} />
                        </div>)}
                    </div>
                </div>
            </Fragment>)}
    </div>;
}