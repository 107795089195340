// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OJn8M89fBEpNGeEcQe6h{font-size:2.2rem;margin:20px 0}.YuHh1qzFxK7MS8VdF5JV label img{width:90px;height:90px;margin:10px 20px;object-fit:contain;vertical-align:middle}.YuHh1qzFxK7MS8VdF5JV label img+div{display:inline-block}hr.Zd1khvm9X1vdBwEnoi5U{margin:16px 0}`, ""]);
// Exports
export var optionInout = `OJn8M89fBEpNGeEcQe6h`;
export var option = `YuHh1qzFxK7MS8VdF5JV`;
export var splitter = `Zd1khvm9X1vdBwEnoi5U`;
export default ___CSS_LOADER_EXPORT___;
